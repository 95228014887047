import type { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import type { Service } from '../mappers/service.mapper';
import type { ActiveFeatures } from '@wix/bookings-uou-types';
import type { Experiments } from '@wix/yoshi-flow-editor';
import { ExperimentsConsts } from '../../consts/experiments';
import { DayfulBookValidations } from './dayful-validations';
import { getNotifyPricingPlanRequest, ValidationBase } from './Validations';
import type { FormApi } from '../../api/FormApi';

export const canCompleteBoooking = async ({
  formApi,
  isDayful,
  wixSdkAdapter,
  service,
  activeFeatures,
  experiments,
}: {
  formApi: FormApi;
  isDayful: boolean;
  wixSdkAdapter: WixOOISDKAdapter;
  service: Service;
  activeFeatures: ActiveFeatures;
  experiments: Experiments;
}) => {
  const isAddPremiumLimitationsToFormEnabled = experiments.enabled(
    ExperimentsConsts.AddPremiumLimitationsToForm,
  );

  const bookingsValidation = isDayful
    ? new DayfulBookValidations(wixSdkAdapter, service, activeFeatures)
    : isAddPremiumLimitationsToFormEnabled
    ? new ValidationBase(wixSdkAdapter, service, activeFeatures)
    : undefined;

  if (bookingsValidation) {
    const { canBook, reason } = await bookingsValidation.canBook();

    if (!canBook && reason) {
      if (!isDayful) {
        if (reason.premiumError) {
          await formApi.notifyOwnerNonPremiumEnrollmentAttempt();
        } else if (reason.pricingPlanError) {
          await formApi.notifyOwnerNonPricingPlanEnrollmentAttempt(
            getNotifyPricingPlanRequest(service, reason),
          );
        }
      }
      await wixSdkAdapter.openUoUPremiumModal(
        service.type,
        'bookings-form',
        isDayful,
      );

      return false;
    }
  }

  return true;
};
