import { ControllerFlowAPI, ILocation } from '@wix/yoshi-flow-editor';
import { DynamicPriceInfo } from '@wix/bookings-uou-types';

export const getURLAdapter = ({ flowAPI }: { flowAPI: ControllerFlowAPI }) => {
  const {
    controllerConfig: {
      wixCodeApi: { location },
    },
  } = flowAPI;
  const URLAdapter = {
    getServiceId() {
      return getQueryValueByName(location, 'bookings_serviceId');
    },
    getSessionId() {
      return getQueryValueByName(location, 'bookings_sessionId');
    },
    getResourceId() {
      return getQueryValueByName(location, 'bookings_resourceId');
    },
    getLocationId() {
      return getQueryValueByName(location, 'bookings_locationId');
    },
    getStartDate() {
      return getQueryValueByName(location, 'bookings_startDate');
    },
    getEndDate() {
      return getQueryValueByName(location, 'bookings_endDate');
    },
    getTimezone() {
      return getQueryValueByName(location, 'bookings_timezone');
    },
    getDynamicPricePreSelection(): DynamicPriceInfo | undefined {
      const dynamicPriceInfo = getQueryValueByName(
        location,
        'bookings_dynamicPriceInfo',
      );

      if (!dynamicPriceInfo) {
        return undefined;
      }

      return JSON.parse(dynamicPriceInfo);
    },
  };

  return URLAdapter;
};

type QueryParamName =
  | 'bookings_sessionId'
  | 'bookings_serviceId'
  | 'bookings_resourceId'
  | 'bookings_locationId'
  | 'bookings_startDate'
  | 'bookings_endDate'
  | 'bookings_timezone'
  | 'bookings_dynamicPriceInfo';

export const getQueryValueByName = (
  location: ILocation,
  queryParamName: QueryParamName,
): string | undefined => {
  const queryParamValue = location.query[queryParamName];
  return queryParamValue;
};
