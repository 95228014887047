import { getSessionStorageAdapter } from './SessionStorageAdapter';
import { PageAPIData } from '../pageApiAdapter';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const resolveSessionStorageData = async ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<PageAPIData | undefined> => {
  const sessionStorageAdapter = getSessionStorageAdapter({ flowAPI });

  const serviceId = sessionStorageAdapter.getServiceId()!;
  const slotAvailability = sessionStorageAdapter.getSlotAvailability();
  const timezone = sessionStorageAdapter.getTimezone()!;
  const isEcom = sessionStorageAdapter.getIsEcom();
  const dynamicPricePreSelection = sessionStorageAdapter.getDynamicPricePreSelection();

  return {
    serviceId,
    slotAvailability,
    timezone,
    isEcom,
    dynamicPricePreSelection,
  };
};
