import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { Submission } from '@wix/forms-ui/types';
import type Experiments from '@wix/wix-experiments';
import { ExperimentsConsts } from '../consts/experiments';

export function getSessionValues(
  wixSdkAdapter: WixOOISDKAdapter,
  key: BookingsQueryParams,
) {
  const valueFromStorage = wixSdkAdapter.getFromSessionStorage(key);

  if (valueFromStorage) {
    return JSON.parse(valueFromStorage);
  }
}

export async function getFieldsValuesInStorage(
  wixSdkAdapter: WixOOISDKAdapter,
  bookingsDataCapsule: BookingsDataCapsule,
  experiments: Experiments,
  key: BookingsQueryParams,
) {
  const isSaveSubmissionWithDataCapsuleEnabled = experiments.enabled(
    ExperimentsConsts.SaveSubmissionWithDataCapsule,
  );

  if (isSaveSubmissionWithDataCapsuleEnabled) {
    return bookingsDataCapsule.getItem(key);
  }

  return getSessionValues(wixSdkAdapter, key);
}

export async function setFieldsValuesInStorage(
  wixSdkAdapter: WixOOISDKAdapter,
  bookingsDataCapsule: BookingsDataCapsule,
  experiments: Experiments,
  submission: Submission,
) {
  if (submission) {
    const isSaveSubmissionWithDataCapsuleEnabled = experiments.enabled(
      ExperimentsConsts.SaveSubmissionWithDataCapsule,
    );

    if (isSaveSubmissionWithDataCapsuleEnabled) {
      await bookingsDataCapsule.setItem(
        BookingsQueryParams.FILLED_FIELDS,
        submission,
      );
    } else {
      wixSdkAdapter.setToSessionStorage(
        BookingsQueryParams.FILLED_FIELDS,
        JSON.stringify(submission),
      );
    }
  }
}

export function setIsEcomInStorage(
  wixSdkAdapter: WixOOISDKAdapter,
  isEcom: string = 'false',
) {
  wixSdkAdapter.setToSessionStorage(BookingsQueryParams.ECOM, isEcom);
}
